import { RJSFSchema } from '@rjsf/utils';

// CUSTOM TYPES
export type Member = {
    email: string;
    first_name: string;
    full_name: string | null;
    id?: Number | null;
    last_name: string;
    role: string | null;
}

export type Organization = {
    id?: string,
    name: string,
    description: string,
    role?: string
}

export type Destination = {
    id: string,
    name: string,
    type: DestinationType,
    owner: Organization,
    base_url: string,
    enabled: boolean,
    configurations?: any,
    status: string
}

export type Source = {
    id: string,
    external_id: string,
    update_frequency: string,
    last_update: string,
    routing_rules: RoutingRule[],
    destinations: Destination[],
    status: string,
    provider: Provider,
    created_at: string
}

export type SourceWithConnections = {
    source: Source,
    connections: any[]
}

export type NewDestination = {
    id: string,
    name: string,
    type: string,
    owner: string,
    url: string,
    enabled: true,
    configuration: object
}

export type DestinationType = {
    id: string,
    name: string,
    description: string,
    value: string,
    actions: IntegrationAction[]
}

export type ProviderType = {
    id: string,
    name: string,
    description: string,
    value: string,
    actions: IntegrationAction[]
}

export type IntegrationAction = {
    id: string,
    name: string,
    type: string,
    value: string,
    description: string,
    schema: RJSFSchema
}

export type Provider = {
    id: string,
    name: string,
    type: ProviderType,
    base_url: string,
    status: string,
    configurations?: any,
    enabled: boolean,
    owner?: Organization
}

export type RoutingRule = {
    id: string,
    name: string
}

export type Connection = {
    provider: Provider,
    destinations: Destination[],
    default_route: RoutingRule,
    owner: Organization,
    status: string,
    id: string,
    enabled: boolean
}

export type NewIntegration = {
    name: string,
    base_url: string,
    enabled: boolean,
    type: string,
    owner: string,
    configurations?: NewConnectionConfiguration[],
    create_default_route: boolean
}

export type NewConnectionConfiguration = {
    action: string,
    data: any
}


export type ConnectionConfiguration = {
    action: IntegrationAction,
    action_id?: string,
    data: any,
    id: string
}

export type ConnectionLog = {
    id: string,
    created_at: string,
    log_level: number,
    log_type: string,
    origin: string,
    value: string,
    title: string,
    created_by: Member,
    details: any,
    is_reversable: boolean,
    revert_data: any
}

export const getConnectionHealthStatuses = (t: (key: string) => string) => [
    {
        name: t("Healthy"),
        value: "healthy"
    },
    {
        name: t("Unhealthy"),
        value: "unhealthy"
    },
    {
        name: t("Needs Review"),
        value: "needs_review"
    },
    {
        name: t("Disabled"),
        value: "disabled"
    }
];

// FACTORY FUNCTIONS
export const buildMember = (
    email: string | null = null,
    first_name: string | null = null,
    full_name: string | null = null,
    last_name: string | null = null,
    role: string | null = null,
    id: Number | null = null): Member => {
    const member: Member = {
        email: email ? email : "",
        first_name: first_name ? first_name : "",
        full_name: full_name,
        last_name: last_name ? last_name : "",
        role: role,
        id: id
    }
    return member;
}

export const buildDestinationType = (
    id: string | null = null,
    name: string | null = null,
    description: string | null = null,
    value: string | null = null,
    actions: IntegrationAction[] | null = null
): DestinationType => {
    const destination_type: DestinationType = {
        id: id ? id : "",
        name: name ? name : "",
        description: description ? description : "",
        value: value ? value : "",
        actions: actions ? actions : []
    }
    return destination_type;
}

export const buildDestination = (
    id: string | null = null,
    name: string | null = null,
    type: DestinationType | null = null,
    owner: Organization | null = null,
    base_url: string | null = null,
    enabled: boolean | null = null,
    configurations: any | null = null,
    status: string | null = null
): Destination => {
    const destination: Destination = {
        id: id ? id : "",
        name: name ? name : "",
        type: type ? type : buildDestinationType(),
        owner: owner ? owner : buildOrganization(),
        base_url: base_url ? base_url : "",
        enabled: enabled ? enabled : true,
        configurations: configurations ? configurations : {},
        status: status ? status : ""
    }
    return destination;
}

export const buildSource = (): Source => {
    const source: Source = {
        id: "",
        external_id: "",
        update_frequency: "",
        last_update: "",
        routing_rules: [],
        destinations: [],
        status: "",
        provider: buildProvider(),
        created_at: ""
    }
    return source;
}

export const buildProviderType = (): ProviderType => {
    const provider_type: ProviderType = {
        id: "",
        name: "",
        description: "",
        value: "",
        actions: [{
            id: "",
            name: "",
            type: "",
            value: "",
            description: "",
            schema: {}
        }]
    }
    return provider_type;
}


export const buildNewIntegration = (create_default_route: boolean): NewIntegration => {
    const new_integration: NewIntegration = {
        name: "",
        base_url: "",
        enabled: true,
        type: "",
        owner: "",
        create_default_route: create_default_route
    }
    return new_integration;
}

export const buildConnection = (
    provider: Provider | null = null,
    destinations: Destination[] | null = null,
    default_route: RoutingRule | null = null,
    owner: Organization | null = null,
    status: string | null = null,
    id: string | null = null,
    enabled: boolean | null = null
): Connection => {
    const connection: Connection = {
        provider: provider ? provider : buildProvider(),
        destinations: destinations ? destinations : [],
        default_route: default_route ? default_route : buildRoutingRule(),
        owner: owner ? owner : buildOrganization(),
        status: status ? status : "",
        id: id ? id : "",
        enabled: enabled ? enabled : true
    }
    return connection;
}

export const buildProvider = (
    id: string | null = null,
    name: string | null = null,
    type: ProviderType | null = null,
    base_url: string | null = null,
    status: string | null = null,
    configurations: any | null = null,
    enabled: boolean | null = null
): Provider => {
    const provider: Provider = {
        id: id ? id : "",
        name: name ? name : "",
        type: type ? type : buildProviderType(),
        base_url: base_url ? base_url : "",
        status: status ? status : "",
        configurations: configurations ? configurations : {},
        enabled: enabled ? enabled : true
    }
    return provider;
}

export const buildRoutingRule = (): RoutingRule => {
    const routing_rule: RoutingRule = {
        id: "",
        name: ""
    }
    return routing_rule;
}

export const buildOrganization = (
    name: string | null = null,
    description: string | null = null,
    role: string | null = null
): Organization => {
    const organization: Organization = {
        name: name ? name : "",
        description: description ? description : "",
        role: role ? role : ""
    }
    return organization;
}

