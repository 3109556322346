import React, { FormEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../common/SelectInput";
import SchemaForm from "../common/SchemaForm";
import { NewIntegration, DestinationType, Organization } from "../common/ObjectTypes";

type CancelChangesConfirmationModalProps = {
    onToggleModal: MouseEventHandler,
    onConfirm: MouseEventHandler
}

const CancelChangesConfirmationModal = ({
    onToggleModal,
    onConfirm
}: CancelChangesConfirmationModalProps) => {
    const { t } = useTranslation();
    return (
        <div className="absolute left-0 z-30">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/3 h-fit overflow-y-scroll shadow rounded-lg">
                    <div className="flex flex-row-reverse">
                        <button
                            type="button"
                            onClick={onToggleModal}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                        >
                            <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">{t("Close modal")}</span>
                        </button>
                    </div>
                    <div className="px-6 pb-6 lg:px-8 h-full">
                        <h2 className="text-4xl font-semibold mb-6">{t("Unsaved Changes")}</h2>
                        <p className="text-secondary-gray text-md mb-6">{t("There are unsaved changes. Would you like to discard the changes or continue editing?")}</p>
                        <div className="flex justify-end mb-2">
                            <button
                                type="button"
                                onClick={onToggleModal}
                                className="bg-very-light-gray text-dark-green rounded px-4 mx-2 font-bold"
                            >
                                {t("Continue Editing")}
                            </button>
                            <button
                                type="button"
                                onClick={onConfirm}
                                className="bg-orange hover:bg-light-orange text-white font-bold rounded px-4 py-2"
                            >
                                {t("Discard Changes")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancelChangesConfirmationModal;