import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Organization } from "../common/ObjectTypes";
import NoResults from "../common/NoResults";
import Pagination from "../common/Pagination";
import { Clear_Large, SearchIcon } from "../common/ProjectIcons";
import { page_size } from "../../api/apiUtils";

type OrganizationsListProps = {
    organizations: Array<Organization>,
    onChangeSearchString: Function,
    onSelectOrg: Function,
    onAddOrg: MouseEventHandler,
    superuser: boolean,
    onPageClicked: Function,
    totalCount: number,
    pageLoaded: boolean,
    take: number,
    offset: number,
    searchString: string,
    onSearch: Function
}

const OrganizationsList = ({
    organizations,
    onChangeSearchString,
    onSelectOrg,
    onAddOrg,
    superuser,
    onPageClicked,
    totalCount,
    pageLoaded,
    take,
    offset,
    searchString,
    onSearch
}: OrganizationsListProps) => {
    const { t } = useTranslation();
    return (
        <div className="bg-very-light-gray">
            <div className="mb-3 flex flex-row justify-between items-end">
                <div className="flex flex-col">
                    <h1 data-testid="organizations_page_header" className="text-3xl font-bold mb-3">{t("Organizations")}</h1>
                    <div className="flex flex-row">
                        <div className="relative">
                            <input
                                id="name"
                                placeholder={t("Search by name") || ""}
                                className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                                value={searchString}
                                onChange={(e) => { onChangeSearchString(e) }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearch(searchString);
                                    }
                                }}>
                            </input>
                            {searchString && (
                                <button
                                    className="absolute right-0 top-0 mt-3.5 mr-1 text-dark-green"
                                    onClick={() => {
                                        onChangeSearchString({ target: { value: '' } })
                                        onSearch()
                                    }}
                                >
                                    <Clear_Large />
                                </button>
                            )}
                        </div>
                        <button className="m-2 bg-dark-green hover:bg-status-green text-white rounded text-sm p-2 h-auto"
                            type="button"
                            onClick={() => onSearch()}
                        >
                            <SearchIcon />
                        </button>
                    </div>
                </div>
                {superuser && <button className="bg-dark-green hover:bg-status-green text-white rounded font-bold px-7 py-3"
                    onClick={onAddOrg}>
                    {t("Create Organization")}
                </button>}
            </div>
            <div className="flex flex-col w-full">
                {organizations.length < 1 &&
                    <NoResults
                        headerMsg={t("No Results Found")}
                        labelMsg={t("You may need to adjust your search or filters to find what you are looking for.")}
                    />}

                {organizations.length >= 1 &&
                    <table className="static w-full cursor-pointer border border-gray-lines table-fixed">

                        <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                            <tr>
                                <th className="p-3 w-1/3">
                                    {t("Name")}
                                </th>
                                <th className="p-3">
                                    {t("Description")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map((org: Organization) => {
                                return (
                                    <tr key={org.id}
                                        className="bg-very-light-gray border border-gray-lines hover:bg-light-blue align-top"
                                        onClick={() => onSelectOrg(org.id)}
                                    >
                                        <td className="p-3 w-1/3">
                                            {org.name}
                                        </td>
                                        <td className="p-3 max-w-2xl">
                                            <p className="max-w-2xl">{org.description}</p>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>}
                {totalCount > page_size && <Pagination
                    showText={true}
                    handlePageClick={onPageClicked}
                    pageCount={Math.ceil(totalCount / take)}
                    resultStart={offset + 1}
                    resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                    totalCount={totalCount}
                    take={take}
                    loading={!pageLoaded}
                    mainclassname="w-full mt-2 flex flex-row justify-between bg-very-light-gray opacity-80"
                />}
            </div>
        </div>
    );
};

export default OrganizationsList;