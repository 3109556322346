import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react";
import { ConnectionLog } from "../common/ObjectTypes";
import Pagination from "../common/Pagination";
import { page_size } from "../../api/apiUtils";
import LogsFilter from "../activityLogs/ActivityLogsFilter";
import { SortedDown, SortedUp } from "../common/ProjectIcons";
import { getUtcOffsets } from "../activityLogs/ActivityLogsHelperFunctions";
import Spinner from "../common/Spinner";
import { useTranslation } from "react-i18next";

type ConnectionLogProps = {
    logs: ConnectionLog[],
    reloadLogs: boolean,
    prevCursor: string | null,
    nextCursor: string | null,
    selectedTimezone: any,
    onSelectTimezone: Function,
    onNextPageClicked: MouseEventHandler,
    onPrevPageClicked: MouseEventHandler,
    requestSort: Function,
    pageLoaded: boolean,
    filters: any[],
    globalSearchStr: string,
    onChangeLogGlobalSearch: Function,
    onChangeLogStatus: ChangeEventHandler,
    onChangeLogType: ChangeEventHandler,
    onChangeLogDate: Function,
    onRemoveLogFilterTag: Function,
    onResetLogFilters: Function
}

const getClassname = (log_level: number) => {
    switch (log_level) {
        case 10:
            return "text-xs text-center rounded rounded-sm bg-light-blue text-blue-basic";
        case 20:
            return "text-xs text-center rounded rounded-sm bg-light-green text-dark-green";
        case 30:
            return "text-xs text-center rounded rounded-sm bg-warning-yellow text-warning-dark-yellow";
        case 40:
            return "text-xs text-center rounded rounded-sm bg-very-light-orange text-orange";
        default:
            return "text-xs text-center rounded rounded-sm bg-light-gray text-medium-gray";
    }

}

const getLogLevelText = (log_level: number) => {
    switch (log_level) {
        case 10:
            return "Debug";
        case 20:
            return "Info";
        case 30:
            return "Warning";
        case 40:
            return "Error";
        default:
            return "--";
    }
}

const ConnectionDetailsLog = ({
    logs,
    reloadLogs,
    prevCursor,
    nextCursor,
    selectedTimezone,
    onSelectTimezone,
    onNextPageClicked,
    onPrevPageClicked,
    requestSort,
    pageLoaded,
    filters,
    globalSearchStr,
    onChangeLogGlobalSearch,
    onChangeLogStatus,
    onChangeLogType,
    onChangeLogDate,
    onRemoveLogFilterTag,
    onResetLogFilters
}: ConnectionLogProps) => {
    const { t } = useTranslation();
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [timezones, setTimezones] = useState<any[]>([]);
    const [sortDirection, setSortDirection] = useState(true);
    const formatDatetime = (datetime: string, utcOffsetStr: string) => {
        const date = new Date(datetime);

        // Parse the UTC offset string
        const sign = utcOffsetStr.startsWith('-') ? -1 : 1;
        const hours = parseInt(utcOffsetStr.slice(0, 3), 10);
        const minutes = parseInt(utcOffsetStr.slice(-2), 10) / 60;
        const utcOffset = sign * (Math.abs(hours) + minutes);

        // Convert utcOffset to milliseconds
        const offsetMilliseconds = utcOffset * 3600000;

        // Adjust the date by the utcOffset
        const adjustedDate = new Date(date.getTime() + offsetMilliseconds);

        const formattedDate = [
            adjustedDate.getUTCFullYear(),
            ('0' + (adjustedDate.getUTCMonth() + 1)).slice(-2),
            ('0' + adjustedDate.getUTCDate()).slice(-2)
        ].join('-') + ' ' +
            [
                ('0' + adjustedDate.getUTCHours()).slice(-2),
                ('0' + adjustedDate.getUTCMinutes()).slice(-2),
                ('0' + adjustedDate.getUTCSeconds()).slice(-2)
            ].join(':');

        return formattedDate;
    };

    const toggleDetails = (id: string) => {
        if (expandedRow === id) {
            setExpandedRow(null)
        } else {
            setExpandedRow(id)
        }
    }

    useEffect(() => {
        let temp = getUtcOffsets();
        setTimezones(temp);
    }, [])

    return (
        <div className="mt-8">
            {/* div with search panel and table*/}
            <div className="flex flex-row items-start w-full">
                {/* search panel component will go here */}
                <LogsFilter
                    filters={filters}
                    globalSearchStr={globalSearchStr}
                    timezones={timezones}
                    selectedTimezone={selectedTimezone}
                    onChangeGlobalSearch={onChangeLogGlobalSearch}
                    onRemoveTag={onRemoveLogFilterTag}
                    onResetFilters={onResetLogFilters}
                    onChangeStatusFilter={onChangeLogStatus}
                    onChangeLogType={onChangeLogType}
                    onChangeLogDate={onChangeLogDate}
                    onSelectTimezone={onSelectTimezone}
                />
                <div className="w-5/6 h-auto overflow-y-scroll mb-32">
                    <div className="flex flex-col w-full">
                        <table className="table-auto h-auto w-full cursor-default border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                            <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                <tr>
                                    <th className="p-3 w-1/12">
                                        {t("Level")}
                                    </th>
                                    <th className="p-3 w-2/12">
                                        {t("Timestamp")} ({selectedTimezone.label})
                                        <span onClick={() => {
                                            requestSort(sortDirection)
                                            setSortDirection(!sortDirection)
                                        }}>
                                            {sortDirection ? <SortedUp /> : <SortedDown />}
                                        </span>
                                    </th>
                                    <th className="p-3 w/7/12">
                                        {t("Title")}
                                    </th>
                                </tr>
                            </thead>
                            {reloadLogs ?
                                <tbody>
                                    <tr>
                                        <td className="py-2 px-4" colSpan={4}><Spinner /></td>
                                    </tr>
                                </tbody>
                                : <tbody className="text-sm">
                                    {logs.map((log: ConnectionLog) => (
                                        <React.Fragment key={log.id}>
                                            <tr key={log.id} className="border-t border-gray-lines">
                                                <td className="py-2 px-4 text-left w-1/12"><p className={getClassname(log.log_level)}>{getLogLevelText(log.log_level)}</p></td>
                                                <td className="py-2 px-4 text-left text-secondary-gray w-2/12">{formatDatetime(log.created_at, selectedTimezone.value)}</td>
                                                <td className="py-2 px-4 text-left w-7/12">{log.title}</td>
                                                <td className="py-2 px-4 cursor-pointer text-right font-semibold text-dark-green w-2/12" onClick={() => toggleDetails(log.id)}>{expandedRow === log.id ? "Hide Details" : "Show Details"}</td>
                                            </tr>
                                            {(expandedRow === log.id) && (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="pl-6 pb-3 w-7/12 text-secondary-gray text-xs">
                                                        <pre>{JSON.stringify(log, null, 2)}</pre>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>}
                        </table>
                        <Pagination
                            showText={false}
                            prevCursor={prevCursor ? prevCursor : undefined}
                            nextCursor={nextCursor ? nextCursor : undefined}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            handleNextPageClick={onNextPageClicked}
                            handlePrevPageClick={onPrevPageClicked}
                            take={page_size}
                            loading={!pageLoaded}
                            mainclassname="w-full mt-2 pt-2 pl-3 flex flex-row justify-end bg-very-light-gray opacity-90"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ConnectionDetailsLog;