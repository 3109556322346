import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Member } from "../common/ObjectTypes";

type RemoveMemberProps = {
    onToggleModal: Function,
    onRemoveMember: Function,
    memberid: string,
    currentMember: Member,
    superuser: boolean
}

const RemoveMemberModal = ({
    onToggleModal,
    onRemoveMember,
    memberid,
    currentMember,
    superuser
}: RemoveMemberProps) => {
    const { t } = useTranslation()
    return (
        <div className="absolute left-0">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={() => onToggleModal()}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/3 h-1/4 shadow rounded">
                    <div className="flex flex-row-reverse">
                        <button
                            type="button"
                            onClick={() => onToggleModal()}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                        >
                            <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">{t("Close modal")}</span>
                        </button>
                    </div>
                    <div className="px-6  lg:px-8 h-full">
                        {(superuser || currentMember.role === "admin") ?
                            <div className="flex flex-col justify-between h-full pb-6">
                                <h2 className="text-xl">Remove User</h2>
                                <p className="text-secondary-gray">Are you sure you want to remove this user from the organization?</p>
                                <div className="flex flex-row-reverse mt-2 ">
                                    <button className="bg-orange hover:bg-light-orange text-white rounded py-2 px-8 ml-2 shadow"
                                        type="submit"
                                        onClick={() => onRemoveMember(memberid)}>
                                        {t("Remove")}
                                    </button>
                                    <button className="bg-very-light-gray text-medium-gray rounded py-2 px-3 mx-2"
                                        type="button"
                                        onClick={() => onToggleModal()}>
                                        {t("Cancel")}
                                    </button>
                                </div>
                            </div> :
                            <div className="flex flex-col justify-between h-full pb-6">
                                <h2 className="text-xl">{t("Action Denied")}</h2>
                                <p className="text-secondary-gray">{t("You do not have permission to remove organization members. Contact support with any questions.")}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoveMemberModal;