import React, { FormEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../common/SelectInput";
import SchemaForm from "../common/SchemaForm";
import { NewIntegration, DestinationType, Organization } from "../common/ObjectTypes";
import { AttentionIcon } from "../common/ProjectIcons";

type DestinationModalProps = {
    errors: any,
    rsjfErrors: any,
    onToggleModal: MouseEventHandler,
    onNextStep: MouseEventHandler,
    stepOne: boolean,
    stepTwo: boolean,
    destination: NewIntegration,
    destinationType: DestinationType,
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function
    destinationTypes: DestinationType[],
    onChange: Function,
    onChangeOrg: Function,
    onChangeType: Function,
    onChangeJSONForm: Function,
    onSave: FormEventHandler
}

const DestinationModal = ({
    errors,
    rsjfErrors,
    onToggleModal,
    onNextStep,
    stepOne,
    stepTwo,
    destination,
    destinationType,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    destinationTypes,
    onChange,
    onChangeOrg,
    onChangeType,
    onChangeJSONForm,
    onSave
}: DestinationModalProps) => {
    const { t } = useTranslation();
    return (
        <div className="absolute left-0 z-30">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/2 h-3/4 overflow-y-scroll shadow rounded-lg">
                    <div className="flex flex-row-reverse">
                        <button
                            type="button"
                            onClick={onToggleModal}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                        >
                            <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">{t("Close modal")}</span>
                        </button>
                    </div>
                    <div className="pb-6 h-full">

                        <div onSubmit={onSave} className="h-full">
                            <div className="flex flex-col h-full">
                                {/* STEP 1: DESTINATION TYPE */}
                                {stepOne &&
                                    <div className="px-6 h-full flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-3xl font-semibold mb-8">{t("New Destination")}</h1>
                                            {/* form fields */}
                                            <div className="flex flex-col">
                                                <label>{t("Type")}</label>
                                                <SelectInput
                                                    options={destinationTypes.map((type: DestinationType) => {
                                                        return {
                                                            label: type.name,
                                                            name: type.name,
                                                            description: type.description,
                                                            id: type.id,
                                                            value: type.id,
                                                            actions: type.actions
                                                        }
                                                    })}
                                                    className={"basic-single text-black"}
                                                    defaultValue={{
                                                        label: "Select",
                                                        value: 0
                                                    }}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name={t("Type")}
                                                    onChange={(e: any) => onChangeType(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="sticky bottom-0 bg-white/50 backdrop-blur-sm flex flex-row mt-2">
                                            {/* next button */}
                                            <button
                                                className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2"
                                                onClick={onNextStep}>
                                                {t("Next")}
                                            </button>
                                            <button className="bg-very-light-gray text-medium-gray rounded px-7 mx-2"
                                                type="button"
                                                onClick={onToggleModal}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>}
                                {/* STEP 2: NAME, CONFIGURATION, ENABLED */}
                                {stepTwo &&
                                    <div className="h-full w-full mb-8 flex flex-col justify-between">
                                        <div className="px-6 w-full">
                                            <h1 className="text-3xl font-semibold mb-8">{t("New")} {destinationType.name} {t("Destination")}</h1>
                                            {/* form fields */}
                                            {/* Name */}
                                            <div className="flex flex-col pb-1">
                                                <label className="text-sm text-secondary-gray">{t("Name")} *</label>
                                                <input
                                                    id="name"
                                                    placeholder=""
                                                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                                                    value={destination.name ? destination.name : ""}
                                                    onChange={(e) => onChange(e)}>
                                                </input>
                                                {errors.name && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.name}</p></div>}
                                            </div>
                                            {/* owner: dropdown of all organizations */}
                                            <div className="flex flex-col pb-1">
                                                <label className="text-sm text-secondary-gray">{t("Organization")} *</label>
                                                <SelectInput
                                                    options={organizations.map((org: Organization) => {
                                                        return {
                                                            label: org.name,
                                                            value: org.id
                                                        }
                                                    })}
                                                    className={"basic-single text-black"}
                                                    defaultValue={{
                                                        label: "Select",
                                                        value: 0
                                                    }}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name={t("Organization")}
                                                    onChange={(e: any) => onChangeOrg(e)}
                                                    onMenuScrollToBottom={loadMoreOrgs}
                                                    handleInputChange={orgInputChange}
                                                />
                                                {errors.owner && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.owner}</p></div>}
                                            </div>
                                            {/* URL */}
                                            <div className="flex flex-col pb-14">
                                                <label className="text-sm text-secondary-gray">{t("URL")} *</label>
                                                <input
                                                    id="base_url"
                                                    placeholder=""
                                                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                                                    value={destination.base_url ? destination.base_url : ""}
                                                    onChange={(e) => onChange(e)}>
                                                </input>
                                                {errors.base_url && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.base_url}</p></div>}
                                            </div>
                                            {/* configuration */}
                                            <div className="">
                                                {/* <label>Configurations</label> */}
                                                {destinationType.actions.filter((item: any) => item.type !== "pull").map((item: any) => {
                                                    const uiSchema = item.ui_schema ? { ...item.ui_schema } : {};
                                                    const formTitle = item.ui_schema && item.ui_schema['ui:title'] ? item.ui_schema['ui:title'] : item.name;
                                                    return (
                                                        <div key={item.id} className={(Object.keys(item.schema).length !== 0) ? "pb-14" : "hidden"}>
                                                            {(Object.keys(item.schema).length !== 0) && <SchemaForm
                                                                formTitle={formTitle}
                                                                schema={item.schema}
                                                                ui_schema={uiSchema}
                                                                onChange={(e: any) => onChangeJSONForm(e, item.id)}
                                                                isEditable={true}
                                                                errors={rsjfErrors[item.id]}
                                                            />}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {/* submit and cancel button */}
                                        <div className="sticky bottom-0 bg-white/50 backdrop-blur-sm flex flex-row my-3 py-3 px-6 w-full">
                                            <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2"
                                                type="submit"
                                                onClick={onSave}>
                                                {t("Add")}
                                            </button>
                                            <button className="font-bold text-medium-gray rounded px-7 mx-2"
                                                type="button"
                                                onClick={onToggleModal}>
                                                {t("Cancel")}
                                            </button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DestinationModal;