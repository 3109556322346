import React, { useState } from "react";
import { Connection } from "../common/ObjectTypes";
import { SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import Pagination from "../common/Pagination";
import NoResults from "../common/NoResults";
import { page_size } from "../../api/apiUtils";
import { useTranslation } from "react-i18next";

type DestinationDetailsModalTableProps = {
    connections: Connection[],
    requestSort: Function,
    loadingConnections: boolean,
    onPageClicked: Function,
    totalCount: number,
    pageLoaded: boolean,
    take: number,
    offset: number
}

const DestinationDetailsModalTable = ({
    connections,
    requestSort,
    loadingConnections,
    onPageClicked,
    totalCount,
    pageLoaded,
    take,
    offset
}: DestinationDetailsModalTableProps) => {
    const { t } = useTranslation();
    const [sortDirection, setSortDirection] = useState(true);
    const [sortByColumn, setSortByColumn] = useState("name");

    return (
        <div>
            <div className="flex flex-col w-full">
                {connections.length < 1 &&
                    <NoResults
                        headerMsg={t("No Results Found")}
                        labelMsg={t("This destination is not associated with any connections.")}
                    />}
                {connections.length >= 1 &&
                    <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                        <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                            <tr>
                                <th className="p-3">
                                    {t("Connection Name")}
                                    <span onClick={() => {
                                        requestSort("name", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByColumn("name")
                                    }}>
                                        {sortByColumn === "name" ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                                <th className="p-3">
                                    {t("Provider")}
                                    <span onClick={() => {
                                        requestSort("type__name", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByColumn("type__name")
                                    }}>
                                        {sortByColumn === "type__name" ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                                <th className="p-3">
                                    {t("Routes")}
                                </th>
                                <th className="p-3">
                                    {t("Organization")}
                                    <span onClick={() => {
                                        requestSort("owner__name", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByColumn("owner__name")
                                    }}>
                                        {sortByColumn === "owner__name" ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        {loadingConnections ?
                            <tbody>
                                <tr>
                                    <td className="p-3">
                                        ...
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody className="text-sm">
                                {connections.map((connection: Connection) => {
                                    return (
                                        <tr key={connection.id} className="bg-very-light-gray border border-gray-lines align-top">
                                            <td className="p-3">
                                                {connection.provider.name}
                                            </td>
                                            <td className="p-3">
                                                {connection.provider.type.name}
                                            </td>
                                            <td className="p-3">
                                                {connection.default_route ? connection.default_route.name : ""}
                                            </td>
                                            <td className="p-3">
                                                {connection.owner.name}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>}
                {totalCount > page_size && <Pagination
                    showText={true}
                    handlePageClick={onPageClicked}
                    pageCount={Math.ceil(totalCount / take)}
                    resultStart={offset + 1}
                    resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                    totalCount={totalCount}
                    take={take}
                    loading={!pageLoaded}
                    mainclassname="pt-2 w-full flex flex-row justify-between bg-very-light-gray opacity-80"
                />}
            </div>
        </div>
    )
}

export default DestinationDetailsModalTable;