import React, { ChangeEventHandler, useRef, useState, useEffect, MouseEventHandler } from "react";
import { ArrowDown, ArrowUp, Clear_Large } from "./ProjectIcons";
import { useTranslation } from "react-i18next";

type RadioSelectListProps = {
    optionList: any[],
    selectedList: any[],
    onChangeFilter?: MouseEventHandler,
    onChangeSearchBar?: ChangeEventHandler,
    sectionLabel: string,
    onClickLoadMore?: () => void,
    loadMore: boolean,
    loading: boolean
}

const RadioSelectList = ({
    optionList,
    selectedList,
    onChangeFilter,
    onChangeSearchBar,
    sectionLabel,
    onClickLoadMore,
    loadMore,
    loading
}: RadioSelectListProps) => {
    const { t } = useTranslation();
    const [subStr, setSubStr] = useState("");
    const [expanded, setExpanded] = useState(true);
    const toggleExpanded = () => setExpanded(!expanded);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleLoadMore = async () => {
        if (onClickLoadMore) {
            await onClickLoadMore();
        }

        // Scroll to the bottom of the container
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    }, []);

    return (
        <div className="flex flex-col max-w-xs">
            {/* input box to refine list */}
            <div className="w-full flex flex-row justify-between" onClick={toggleExpanded}>
                <label className="font-semibold">{sectionLabel}</label>{expanded ? <ArrowUp /> : <ArrowDown />}
            </div>
            {expanded &&
                <div>
                    <div className="relative">
                        <input
                            id="name"
                            placeholder=""
                            value={subStr}
                            className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2 pr-8 w-full" // Increase padding-right to make space for the button
                            onChange={(e) => {
                                setSubStr(e.target.value)
                                onChangeSearchBar && onChangeSearchBar(e)
                            }}
                        />
                        {subStr && (
                            <button
                                className="absolute right-1.5 top-1/2 transform -translate-y-1/2 text-dark-green" // Adjust right and top to align inside the input, use transform for vertical centering
                                onClick={() => {
                                    setSubStr('')
                                    onChangeSearchBar && onChangeSearchBar({ target: { value: '' } } as any)
                                }}
                            >
                                <Clear_Large />
                            </button>
                        )}
                    </div>
                    {/* list of all the options */}
                    <div className="max-h-40 overflow-scroll" ref={scrollContainerRef}>
                        {subStr.length === 0 &&
                            optionList.map((item: any) => {
                                return item.name && (
                                    <div className="flex flex-row" key={item.id}>
                                        <input
                                            className="accent-dark-green cursor-pointer"
                                            type="radio"
                                            value={item.id}
                                            id={item.attribute_name}
                                            name={item.name}
                                            checked={selectedList.map(a => a.id).includes(item.id)}
                                            onClick={onChangeFilter} />
                                        <label
                                            className="inline-block mx-2 text-off-black truncate"
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                )
                            })}
                        {subStr.length > 0 &&
                            optionList.map((item: any) => {
                                return item.name && item.name.toLowerCase().includes(subStr.toLowerCase()) && (
                                    <div className="flex flex-row" key={item.id}>
                                        <input
                                            className="accent-dark-green cursor-pointer"
                                            type="radio"
                                            value={item.id}
                                            id={item.attribute_name}
                                            name="radioGroup"
                                            checked={selectedList.map(a => a.id).includes(item.id)}
                                            onClick={onChangeFilter} />
                                        <label
                                            className="inline-block hover:cursor-pointer mx-2 text-off-black truncate"
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                )
                            })}
                    </div>
                    <label className={loadMore ? loading ? "text-medium-gray text-sm cursor-pointer" : "text-dark-green text-sm cursor-pointer" : "hidden"} onClick={handleLoadMore}>{loading ? t("Loading") : t("Load More")}</label>
                </div>}

        </div>
    )
}

export default RadioSelectList;