import React, { useState, ChangeEventHandler } from "react";
import { useTranslation } from 'react-i18next';
import GlobalSearch from "../common/GlobalSearch";
import { Clear, FilterIcon } from "../common/ProjectIcons";
import SelectList from "../common/SelectList";
import { DestinationType, Organization, ProviderType } from "../common/ObjectTypes";

type SourceFilterProps = {
    filters: any[],
    globalSearchStr?: string,
    organizations: Organization[],
    destinationUrls: string[],
    providerTypes: ProviderType[],
    destinationTypes: DestinationType[],
    loadingProviderTypes?: boolean,
    loadMoreProviderTypes?: boolean,
    onLoadMoreProviderTypes?: Function,
    onChangeOwner?: ChangeEventHandler,
    onChangeOwnerSearchBar?: ChangeEventHandler,
    onLoadMoreOrganizations?: Function,
    loadingOrganizations?: boolean,
    loadMoreOrganizations?: boolean,
    onChangeDestinationType?: ChangeEventHandler,
    onLoadMoreDestinationTypes?: Function,
    onChangeProviderType?: ChangeEventHandler,
    onChangeEndpoint: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadMoreDestinationUrls: boolean,
    loadingDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    onChangeProviderTypeSearchBar?: ChangeEventHandler,
    onChangeGlobalSearch: Function,
    onRemoveTag: any,
    onResetFilters: any,
    showOrgFilter: boolean
    showProviderTypeFilter: boolean,
    showDestinationTypeFilter: boolean,
    onChangeDestinationTypeSearchBar?: Function,
    loadingDestinationTypes?: boolean,
    loadMoreDestinationTypes?: boolean
};

const SourceFilter = ({
    filters,
    globalSearchStr,
    organizations,
    destinationUrls,
    providerTypes,
    destinationTypes,
    loadMoreProviderTypes,
    loadingProviderTypes,
    onLoadMoreProviderTypes,
    onChangeOwner,
    onChangeOwnerSearchBar,
    loadingOrganizations,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationType,
    onChangeProviderType,
    onChangeDestinationUrlSearchBar,
    loadMoreDestinationUrls,
    loadingDestinationUrls,
    onLoadMoreDestinationUrls,
    onChangeProviderTypeSearchBar,
    onChangeEndpoint,
    onChangeGlobalSearch,
    onRemoveTag,
    onResetFilters,
    showOrgFilter,
    showProviderTypeFilter,
    showDestinationTypeFilter,
    onLoadMoreDestinationTypes,
    onChangeDestinationTypeSearchBar,
    loadingDestinationTypes,
    loadMoreDestinationTypes
}: SourceFilterProps) => {
    const { t } = useTranslation();

    return (
        <div className="text-off-black bg-very-light-gray mr-3 mb-3 h-auto pb-3 px-3 divide-y divide-gray-lines max-h-screen overflow-y-scroll overflow-x-hidden">
            <GlobalSearch
                onChangeGlobalSearch={onChangeGlobalSearch}
                savedString={globalSearchStr}
            />
            <div className="flex flex-row justify-between mt-2 pt-2">
                <h3><FilterIcon /> {t("Filters")}</h3>
                {filters.length > 0 && <h3 className="bg-dark-green text-white text-sm py-1 px-3 rounded-full">{filters.length}</h3>}
            </div>
            {/* tags of all of the filters */}
            {filters.length > 0 &&
                <div className="flex flex-col mt-3 pt-3">
                    {filters.map((item: any) => {
                        return (
                            <p className="text-sm w-fit py-1 px-2 bg-light-green mt-1 rounded-sm items-end"
                                key={item.id}
                            >
                                {item.name}
                                <span className="pb-3 text-dark-green cursor-pointer"
                                    onClick={() => onRemoveTag(item.id)}>
                                    <Clear />
                                </span>
                            </p>
                        )
                    })}
                    <button className="w-fit bg-very-light-gray text-dark-green mt-3"
                        onClick={onResetFilters}
                    >{t("Clear All")}</button>
                </div>}
            {/* provider */}
            {showProviderTypeFilter && <div className="my-6 pt-3">
                <SelectList
                    optionList={providerTypes.map((provider) => {
                        return {
                            name: provider.name,
                            id: provider.value,
                            attribute_name: "provider_type__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeProviderType}
                    onChangeSearchBar={onChangeProviderTypeSearchBar}
                    sectionLabel={t("Filter By Provider Type")}
                    loadMore={loadMoreProviderTypes || false}
                    loading={loadingProviderTypes || false}
                    onClickLoadMore={() => onLoadMoreProviderTypes && onLoadMoreProviderTypes()}
                />
            </div>}
            {/* destination url */}
            <div className="my-6 pt-3">
                <SelectList
                    optionList={destinationUrls.map((url) => {
                        const new_url = new URL(url)
                        return {
                            name: new_url.hostname,
                            id: url,
                            attribute_name: "destination_url__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeEndpoint}
                    onChangeSearchBar={onChangeDestinationUrlSearchBar}
                    sectionLabel={t("Filter by Destination")}
                    loadMore={loadMoreDestinationUrls}
                    onClickLoadMore={() => onLoadMoreDestinationUrls()}
                    loading={loadingDestinationUrls}
                />
            </div>
            {/* destination type */}
            {showDestinationTypeFilter && <div className="my-6 pt-3">
                <SelectList
                    optionList={destinationTypes.map((dest) => {
                        return {
                            name: dest.name,
                            id: dest.value,
                            attribute_name: "destination_type__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeDestinationType}
                    sectionLabel={t("Destination Type")}
                    onChangeSearchBar={() => onChangeDestinationTypeSearchBar && onChangeDestinationTypeSearchBar()}
                    loadMore={loadMoreDestinationTypes || false}
                    onClickLoadMore={() => onLoadMoreDestinationTypes && onLoadMoreDestinationTypes()}
                    loading={loadingDestinationTypes || false}
                />
            </div>}
            {/* organization */}
            {showOrgFilter && <div className="flex flex-col my-6 pt-3">
                <SelectList
                    optionList={organizations.map((org) => {
                        return {
                            name: org.name,
                            id: org.id,
                            attribute_name: "owner__in"
                        }
                    })}
                    selectedList={filters}
                    onChangeFilter={onChangeOwner}
                    sectionLabel={t("Filter by Organization")}
                    loadMore={loadMoreOrganizations || false}
                    onChangeSearchBar={onChangeOwnerSearchBar}
                    onClickLoadMore={() => onLoadMoreOrganizations && onLoadMoreOrganizations()}
                    loading={loadingOrganizations || false}
                />
            </div>}
        </div>
    )
};

export default SourceFilter;