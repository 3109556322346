import React, { FormEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Member } from "../common/ObjectTypes";
import SelectInput from "../common/SelectInput";
import { AttentionIcon } from "../common/ProjectIcons";

type MemberModalProps = {
    member: Member,
    existingMember: Member,
    onChange: Function,
    onSelectRole: Function,
    onToggleModal: MouseEventHandler,
    onSave: FormEventHandler,
    newMember: boolean,
    currentMember: Member,
    superuser: boolean,
    errors: any,
}

const MemberModal = ({
    member,
    existingMember,
    onChange,
    onSelectRole,
    onToggleModal,
    onSave,
    newMember,
    currentMember,
    superuser,
    errors
}: MemberModalProps) => {
    const { t } = useTranslation();
    return (
        <div className="absolute left-0">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/2 max-w-2xl h-2/3 shadow rounded">
                    <div className="h-full">
                        <div className="flex flex-row-reverse">
                            <button
                                type="button"
                                onClick={onToggleModal}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                data-modal-hide="authentication-modal"
                            >
                                <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">{t("Close modal")}</span>
                            </button>
                        </div>
                        <div className="px-6 pb-6 lg:px-8 h-full">
                            <div className="flex justify-between">
                                <h1 className="text-3xl font-semibold">{(currentMember.role === "admin" || superuser) ? (newMember ? t("Add User") : t("Update User")) : t("User Details")}</h1>
                            </div>
                            <div onSubmit={onSave} className="h-full">
                                <div className="relative flex flex-col my-3 h-full justify-between">
                                    <div>
                                        {/* email, role, first name, last name */}
                                        <div className="flex flex-col w-1/2">
                                            <label>{t("Email")}*</label>
                                            <input
                                                id="email"
                                                placeholder=""
                                                className="mt-2 p-1.5 border border-neutral-gray-200 rounded px-2"
                                                value={newMember ? member.email : existingMember?.email}
                                                onChange={(e) => onChange(e)}
                                                disabled={currentMember.role === "viewer" && !superuser}>
                                            </input>
                                            {errors.email && <div className="flex flex-row text-orange-600 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.email}</p></div>}
                                        </div>
                                        <div className="flex flex-col w-1/2 my-2">
                                            <label className="mb-2">{t("Role")}*</label>
                                            <SelectInput
                                                options={[{
                                                    label: t("Admin"),
                                                    value: "admin"
                                                }, {
                                                    label: t("Viewer"),
                                                    value: "viewer"
                                                }]}
                                                className={"basic-single text-black"}
                                                defaultValue={newMember ? {
                                                    label: t("Select"),
                                                    value: 0
                                                } : {
                                                    label: existingMember.role,
                                                    value: existingMember.role
                                                }}
                                                isDisabled={currentMember.role === "viewer" && !superuser}
                                                isLoading={false}
                                                isClearable={false}
                                                isSearchable={true}
                                                isMulti={false}
                                                name={"Type"}
                                                onChange={(e: any) => onSelectRole(e)}
                                            />
                                            {errors.role && <div className="flex flex-row text-orange-600 py-1 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.role}</p></div>}
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label>{t("First Name")}*</label>
                                            <input
                                                id="first_name"
                                                placeholder=""
                                                className="my-2 p-1.5 border border-neutral-gray-200 rounded px-2"
                                                value={newMember ? member.first_name : existingMember.first_name}
                                                onChange={(e) => onChange(e)}
                                                disabled={currentMember.role === "viewer" && !superuser}>
                                            </input>
                                            {errors.first_name && <div className="flex flex-row text-orange-600 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.first_name}</p></div>}
                                        </div>
                                        <div className="flex flex-col w-1/2 my-2">
                                            <label>{t("Last Name")}*</label>
                                            <input
                                                id="last_name"
                                                placeholder=""
                                                className="my-2 p-1.5 border border-neutral-gray-200 rounded px-2"
                                                value={newMember ? member.last_name : existingMember.last_name}
                                                onChange={(e) => onChange(e)}
                                                disabled={currentMember.role === "viewer" && !superuser}>
                                            </input>
                                            {errors.last_name && <div className="flex flex-row text-orange-600 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.last_name}</p></div>}
                                        </div>
                                    </div>
                                </div>
                                {/* submit and cancel button */}
                                {(currentMember.role === "admin" || superuser) && <div className="flex flex-row-reverse mt-2">
                                    <button className="bg-dark-green hover:bg-status-green text-white rounded py-2 px-8 ml-2 shadow"
                                        type="submit"
                                        onClick={onSave}>
                                        {newMember ? t("Add") : t("Update")}
                                    </button>
                                    <button className="bg-very-light-gray text-medium-gray rounded py-2 px-3 mx-2"
                                        type="button"
                                        onClick={onToggleModal}>
                                        {t("Cancel")}
                                    </button>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberModal;