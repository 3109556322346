import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Member, Organization } from "../common/ObjectTypes";
import { BackArrow, Delete } from "../common/ProjectIcons";
import NoResults from "../common/NoResults";

type OrganizationDetailsProps = {
    orgMembers: Member[],
    organization: Organization,
    onBackArrow: MouseEventHandler,
    onClickInvite: MouseEventHandler,
    onClickMember: Function,
    onToggleModal: MouseEventHandler,
    onToggleRemoveMemberModal: Function,
    currentMember: Member,
    superuser: boolean
}

const OrganizationDetails = ({
    orgMembers,
    organization,
    onBackArrow,
    onToggleModal,
    onClickInvite,
    onClickMember,
    onToggleRemoveMemberModal,
    currentMember,
    superuser
}: OrganizationDetailsProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="text-blue-basic flex flex-row text-sm mb-3 cursor-pointer hover:underline w-min"
                onClick={onBackArrow}>
                <BackArrow />  <label className="ml-2">{t("Organizations")}</label>
            </div>
            <div className="mb-3 flex justify-between">
                <div>
                    <h1 className="text-3xl font-bold">{organization.name}</h1>

                </div>
                {(currentMember.role === "admin" || superuser) && <div>
                    <button className="hover:bg-light-gray text-dark-green rounded px-5 py-2 mr-3"
                        onClick={onToggleModal}>
                        {t("Update")}
                    </button>
                    <button className="bg-dark-green hover:bg-status-green text-white rounded px-5 py-2"
                        onClick={onClickInvite}
                    >
                        {t("Add User")}
                    </button>
                </div>}
            </div>
            {orgMembers.length < 1 &&
                <NoResults
                    headerMsg={t("No Members Found")}
                    labelMsg={t("You may need to add your first member, or contact system admin.")}
                />}
            {orgMembers.length >= 1 &&
                <table className="static w-full cursor-pointer border border-gray-lines">
                    <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                        <tr>
                            <th className="p-3">
                                {t("Name")}
                            </th>
                            <th className="p-3">
                                {t("Email")}
                            </th>
                            {/* <th className="p-3">
                                    Last Login
                                </th> */}
                            <th className="p-3">
                                {t("Role")}
                            </th>
                            {(currentMember.role === "admin") && <th className="p-3">

                            </th>}
                        </tr>
                    </thead>
                    <tbody>
                        {orgMembers.map((org_member: Member) => {
                            return (
                                <tr key={org_member.id ? org_member.id.toString() : Math.random()}
                                    className="bg-very-light-gray border border-gray-lines hover:bg-light-gray">
                                    <td className="p-3"
                                        onClick={() => onClickMember(org_member.id)}>
                                        {org_member.full_name}
                                    </td>
                                    <td className="p-3"
                                        onClick={() => onClickMember(org_member.id)}>
                                        {org_member.email}
                                    </td>
                                    {/* <td className="p-3">
                                            Non mvp
                                        </td> */}
                                    <td className="p-3"
                                        onClick={() => onClickMember(org_member.id)}>
                                        {org_member.role}
                                    </td>
                                    {(currentMember.role === "admin" || superuser) && <td className="p-3 text-right"
                                        onClick={() => onToggleRemoveMemberModal(org_member.id)}>
                                        <Delete />
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>}
        </div>
    );
};

export default OrganizationDetails;