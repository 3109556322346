export const getConnectionStatus = (status: string, details_page: boolean, t: (key: string) => string) => {
    switch (status) {
        case "healthy":
            return { text: t("Healthy"), style: `${details_page ? 'font-semibold px-2 py-1' : 'text-xs'} text-center rounded rounded-sm bg-light-green text-dark-green` };
        case "unhealthy":
            return { text: t("Unhealthy"), style: `${details_page ? 'px-2 py-1' : 'text-xs'} text-center rounded rounded-sm bg-very-light-orange text-orange` };
        case "needs_review":
            return { text: t("Needs Review"), style: `${details_page ? 'font-semibold px-2 py-1' : 'text-xs'} text-center rounded rounded-sm bg-warning-yellow text-warning-dark-yellow` };
        case "disabled":
            return { text: t("Disabled"), style: `${details_page ? 'font-semibold px-2 py-1' : 'text-xs'} text-center rounded rounded-sm bg-light-gray text-medium-gray` };
        default:
            return { text: t("Unknown"), style: `${details_page ? 'font-semibold px-2 py-1' : 'text-xs'} text-center rounded rounded-sm bg-light-gray text-medium-gray` };
    }
};