import React from 'react';
import { FieldTemplateProps } from '@rjsf/utils';
import { CustomArrayField } from './CustomArrayField';

interface CustomObjectTemplateProps {
  schema: any;
  formData: { [key: string]: any } | undefined;
  onChange: (value: any) => void;
};

const CustomObjectTemplate: React.FC<CustomObjectTemplateProps> = ({ schema, formData, onChange }) => {
  let obj: { [key: string]: any } = schema.properties as { [key: string]: any };

  const handleFieldChange = (key: string, value: any) => {
    if (formData) {
      onChange({ ...formData, [key]: value });
    }
  };

  const renderAttributes = () => {
    return Object.keys(obj).map(key => {
      return (
        <div key={key} className='flex flex-col'>
          {/* <label className='text-sm font-semibold'>{obj[key].title}</label> */}
          {/* <label>OBJECT {obj[key].type}</label> */}
          {obj[key].type === 'array' ? (
            <div className='flex flex-col ml-3'>
              <CustomArrayField
                schema={obj[key]}
                formData={formData ? formData[key] : undefined}
                onChange={(value: any) => handleFieldChange(key, value)}
              />
            </div>

          ) : (
            <div className='flex flex-col ml-3'>
              <label>{obj[key].title}</label>
              <input
                className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                value={formData ? formData[key] : ''}
                onChange={(e) => handleFieldChange(key, e.target.value)}
              />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div>
      {schema.title}
      {renderAttributes()}
    </div>
  );
};

export default CustomObjectTemplate;