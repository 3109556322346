import React, { useEffect } from 'react';
import { FieldTemplateProps } from '@rjsf/utils';
import { Delete } from '../ProjectIcons';
import CustomObjectTemplate from './CustomObjectTemplate';
import CustomCheckbox from './CustomCheckbox';
import { Tooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

type ArrayElementType = string | { key: string; value: any };
interface CustomArrayFieldProps {
  schema: any;
  formData: any;
  onChange: (value: any) => void;
}

export const CustomArrayField: React.FC<CustomArrayFieldProps> = ({ schema, formData, onChange }) => {
  const { title, items } = schema;
  const itemsData = formData || [];
  const { t } = useTranslation();
  useEffect(() => {
    // Ensure that formData is initialized properly
    if (!formData) {
      onChange([]);
    }
  }, [formData, onChange]);

  const handleAddItem = () => {
    let newItem: any;
    if (typeof items === 'object' && items !== null && 'enum' in items && items.enum) {
      newItem = items.enum[0]; // Default to the first enum value
    } else {
      newItem = ''; // Default to an empty string for arrays of strings
    }
    const updatedItems = [...itemsData, newItem];
    onChange(updatedItems);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = [...itemsData];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  return (
    <div className='my-2'>
      <div className='visible flex flex-row items-baseline'>
        <label className='font-semibold'>{title} </label>
        <label key={Math.random()} className="flex flex-row text-secondary-gray">
          {schema && schema.description && <Tooltip position="right" description={schema.description} />}
        </label>
      </div>

      <ul className="w-full">
        {schema.uniqueItems ? (
          items.enum.map((enumItem: any, enumIndex: number) => (
            <li key={enumIndex} className="flex flex-col">
              <CustomCheckbox
                value={enumIndex}
                label={enumItem}
                checked={itemsData.includes(enumItem)}
                itemsData={itemsData}
                enumItem={enumItem}
                onChange={() => {
                  const updatedItems = !itemsData.includes(enumItem)
                    ? [...itemsData, enumItem]
                    : itemsData.filter((item: any) => item !== enumItem);
                  onChange(updatedItems);
                }}
              />
            </li>
          ))
        ) : (
          itemsData.map((item: ArrayElementType, index: number) => (
            <li key={index} className="flex flex-row mb-3 border border-gray-lines w-fit p-2">
              {
                typeof items === 'object' && items !== null && 'enum' in items ? (
                  <select
                    className="border border-gray-lines rounded py-1 px-2 text-black w-full"
                    value={typeof item === 'string' ? item : item.key}
                    onChange={(e) => {
                      const value = e.target.value;
                      const updatedItems = [...itemsData];
                      updatedItems[index] = typeof item === 'string' ? value : { key: value, value: value };
                      onChange(updatedItems);
                    }}
                  >
                    {typeof items === 'object' && items !== null && 'enum' in items && items.enum ? (
                      items.enum.map((enumItem: any, enumIndex: number) => (
                        <option key={enumIndex} value={enumItem}>
                          {enumItem}
                        </option>
                      ))
                    ) : null}
                  </select>
                ) : typeof items === 'object' && items !== null && items.type === 'object' ? (
                  <CustomObjectTemplate
                    schema={items}
                    formData={typeof item === 'object' && item !== null ? item : {}}
                    onChange={(value: any) => {
                      const updatedItems = [...itemsData];
                      updatedItems[index] = value;
                      onChange(updatedItems);
                    }}
                  />
                ) : (

                  <input
                    type="text"
                    className="border border-gray-lines rounded py-1 px-2 text-black w-full"
                    value={(item as string) || ''}
                    placeholder={t("Enter your text here") || ""}
                    onChange={(e) => {
                      const updatedItems = [...itemsData];
                      updatedItems[index] = e.target.value;
                      onChange(updatedItems);
                    }}
                  />
                )}
              <button type="button" className="bg-very-light-orange text-orange py-2 px-2 mx-2 h-12" onClick={() => handleRemoveItem(index)}>
                <Delete />
              </button>
            </li>
          ))
        )}

      </ul>
      {!schema.uniqueItems && <button type="button" className="bg-light-green rounded-sm text-dark-green font-semibold py-2 px-3" onClick={handleAddItem}>
        Add Item
      </button>}
    </div>
  );
};
