import React from "react";
import { useTranslation } from "react-i18next";

const AccountPage = ({ }) => {
    const { t } = useTranslation();
    return (
        <div>{t("My Account")}</div>
    );
};

export default AccountPage;