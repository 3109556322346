import React, { useState, useEffect } from "react";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { RJSFSchema, UiSchema, WidgetProps } from '@rjsf/utils';
import { generateUISchema } from "./SchemaFormTemplates/generateUISchema";
import { useTranslation } from "react-i18next";

// Define the types for SchemaFormProps
type SchemaFormProps = {
  formTitle: string,
  schema: RJSFSchema,
  ui_schema: UiSchema,
  formdata?: Object,
  onChange: Function
  isEditable: boolean,
  errors?: any
}

const SchemaForm = ({
  formTitle,
  schema,
  ui_schema,
  formdata,
  onChange,
  isEditable,
  errors
}: SchemaFormProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [customSchema, setCustomSchema] = useState<UiSchema>({});
  const [formData, setFormData] = useState(formdata ? formdata : null);
  const [loading, setLoading] = useState(true); // Add loading state
  const printError = (error: any) => console.error("schema form error", { error });
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const generatedUISchema = generateUISchema(schema, ui_schema, errors, showPassword, togglePassword);
    setCustomSchema(generatedUISchema);
    setLoading(false);
  }, [schema, ui_schema, errors, showPassword]);

  useEffect(() => {
    setFormData(formdata || {});
  }, []);

  const handleFormChange = (formData: any) => {
    setFormData(formData); // Update local state with the updated form data
    onChange(formData); // Propagate the change to the parent component
  };

  if (loading) {
    return <div>{t("Loading")}...</div>; // Render a loading indicator while the schema is being generated
  }

  return (
    <div className="flex flex-col" id="json-form">
      <div className="text-lg font-semibold text-secondary-gray">{formTitle}</div>
      <div className="custom-form">
        <Form
          schema={schema}
          uiSchema={customSchema}
          validator={validator}
          onChange={(e) => {
            setFormData(e.formData)
            onChange(e.formData)
          }}
          onError={printError}
          children={true}
          formData={formData}
          disabled={!isEditable}
        />
      </div>
    </div>
  )
}

export default SchemaForm;
