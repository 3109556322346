import React from "react";
import { UiSchema } from "@rjsf/utils";
import { Tooltip } from "../Tooltip";
import CustomObjectTemplate from "./CustomObjectTemplate";
import CustomCheckbox from "./CustomCheckbox";
import CustomRangeWidget from "./CustomRangeWidget";
import { CustomArrayField } from "./CustomArrayField";
import { AttentionIcon, HideString, ShowString } from "../ProjectIcons";

const customWidgets: any = {
    range: CustomRangeWidget,
    boolean: CustomCheckbox,
    object: CustomObjectTemplate,
    array: CustomArrayField,
};

export const generateUISchema = (
    schema: any,
    providedUiSchema: UiSchema = {},
    errors: any,
    showPassword: boolean,
    togglePassword: () => void
) => {
    if (schema && schema.properties) {
        const uiSchema: UiSchema = { ...providedUiSchema };

        if (schema.title) {
            uiSchema['ui:title'] = '';
        }

        Object.keys(schema.properties).forEach((propertyName) => {
            const isRequired = schema.required && schema.required.includes(propertyName);
            const title = schema.properties[propertyName].title || (propertyName.charAt(0).toUpperCase() + propertyName.slice(1)) || '';
            const description = schema.properties[propertyName].description || '';

            // Initialize the property in uiSchema if it doesn't exist
            uiSchema[propertyName] = uiSchema[propertyName] || {};

            // Set the title with required indicator
            uiSchema[propertyName]['ui:title'] = (
                <div className="flex items-baseline">
                    <span className="mr-1">{title}</span>
                    {isRequired && <span className="mr-1"> * </span>}
                    {description && <Tooltip position="right" description={description} />}
                </div>
            );

            // Handle custom widgets and default widgets
            const widgetType = uiSchema[propertyName]['ui:widget'];
            const propertyType = schema.properties[propertyName].type;
            const propertyFormat = schema.properties[propertyName].format;

            if (widgetType && customWidgets[widgetType]) {
                // Scenario 1: ui_schema contains a widget specification and a custom widget is available
                uiSchema[propertyName]['ui:widget'] = customWidgets[widgetType];
            } else if (widgetType) {
                // Scenario 2: ui_schema contains a widget specification but no custom widget is available
                uiSchema[propertyName]['ui:widget'] = widgetType;
            } else if (propertyFormat === 'password') {
                // Handle password field separately
                uiSchema[propertyName]['ui:widget'] = 'password';
                uiSchema[propertyName]['ui:options'] = {
                    inputType: showPassword ? 'text' : 'password',
                };
                uiSchema[propertyName]['ui:help'] = (
                    <div className="flex flex-row">
                        <div className="mr-3"> Password Visibility </div>
                        <div onClick={togglePassword} className="flex flex-row cursor-pointer">
                            {showPassword ? <ShowString /> : <HideString />}
                        </div>
                    </div>
                );
            } else if (customWidgets[propertyType]) {
                // Scenario 3: ui_schema does not have a widget specification, but a custom widget is available for the property type
                uiSchema[propertyName]['ui:field'] = customWidgets[propertyType];
            }

            // Conditionally set the CSS style to be 'display: none' for the title when the type is boolean
            if (propertyType === 'boolean') {
                uiSchema[propertyName]['ui:title'] = (
                    <div style={{ display: 'none' }}></div>
                );
            } else {
                // Set the title with required indicator and description as a tooltip
                uiSchema[propertyName]['ui:title'] = (
                    <div className="flex items-baseline">
                        <span className="mr-1">{title}</span>
                        {isRequired && <span className="mr-1"> * </span>}
                        {description && <Tooltip position="right" description={description} />}
                    </div>
                );
            }

            // Handle errors
            if (errors && errors[propertyName]) {
                const errorMessage = errors[propertyName];
                uiSchema[propertyName]['ui:options'] = {
                    ...uiSchema[propertyName]['ui:options'],
                    className: 'border border-orange-600 focus:border-orange-600 focus:ring-orange-600'
                };
                uiSchema[propertyName]['ui:help'] = (
                    <div className="text-orange-600 py-1 rounded flex flex-row items-center">
                        <AttentionIcon /> <p className="ml-1 mt-0.5">{errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}</p>
                    </div>
                );
            }
        });

        // Preserve ui:order if it exists in the providedUiSchema
        if (providedUiSchema['ui:order']) {
            uiSchema['ui:order'] = providedUiSchema['ui:order'];
        }

        return uiSchema;
    }

    return {};
};