export function getButtonText(value: string, t: (key: string) => string): string {
    switch (value) {
        case 'auth':
            return t("Test Connection");
        case 'fetch_samples':
            return t("Fetch Data");
        default:
            return t("Test Action");
    }
}

export function getResponseText(value: string, testActionResponse: any, t: (key: string) => string): any[] {
    switch (value) {
        case 'auth':
            return testActionResponse.valid_credentials ? [t("Valid Credentials"), true] : [t("Invalid Credentials"), false];
        case 'fetch_samples':
            return (testActionResponse.observations.length > 0) ? [t("Fetch Successful"), true] : [t("Fetch Unsuccessful"), false];
        default:
            return [t("Response Received"), null];
    }
}